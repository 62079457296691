import React, { useRef, useState } from "react";
import { Box, Button, ButtonGroup, Card, Checkbox, CircularProgress, IconButton, List, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import REQUESTS from "../../../../net/requests";
import QUERY_KEYS from "../../../../net/query-keys";
import ConsentsList from "../ConsentsList";
import ServerError from "../../ServerError/ServerError";
import UserListItem from "../UserListItem";
import styles from "./UsersList.module.css"
import SourcePlatform from "../../../../constants/sourcePlatform";
import { useTranslation } from "react-i18next";
import { userDisplayName } from "../../../../utils/formatUtils";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterRealUsers from "./FilterRealUsers";

const UsersList = () => {
  const { t } = useTranslation();
  const [source, setSource] = useState(null)
  const [filter, setFilter] = useState("")
  const [realOnly, setRealOnly] = useState(null)
  const usersRequest = useQuery(QUERY_KEYS.USERS, REQUESTS.getUsers, { refetchOnMount: true })

  if (usersRequest.isLoading) {
    return <CircularProgress />
  }
  if (usersRequest.isError) {
    return <ServerError/>
  }

  const users = usersRequest.data.sort((a, b) => a.id - b.id)

  const handleChange = (event) => {
    setSource(event.target.value === "" ? null : parseInt(event.target.value))
  }

  const handleChangeFilter = (event) => {
    setFilter(event.target.value)
  }

  const applyAllFilters = (users) => {
    return users
      .filter(item => source === null || item.sourcePlatform === source)
      .filter(item => realOnly === null || item.isFake !== realOnly)
      .filter(item => filter === "" || (item.email && item.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1) || (userDisplayName(item, false).toLowerCase().indexOf(filter.toLowerCase()) !== -1))
  }

  return (
    <div className={styles.box}>
      <div>
        <ToggleButtonGroup
          color="primary"
          value={source}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton sx={{textTransform: 'none'}} all value={null}>Все</ToggleButton>
          <ToggleButton sx={{textTransform: 'none'}} value={SourcePlatform.UNKNOWN}>Нет</ToggleButton>
          <ToggleButton sx={{textTransform: 'none'}} value={SourcePlatform.INST}>Instagram</ToggleButton>
          <ToggleButton sx={{textTransform: 'none'}} value={SourcePlatform.GOOGLE}>Google</ToggleButton>
          <ToggleButton sx={{textTransform: 'none'}} value={SourcePlatform.OTHER}>Другая</ToggleButton>
        </ToggleButtonGroup>
        <div className={styles.filterBox}>
          <TextField value={filter} onChange={handleChangeFilter} label={ t('search') }/>
          <FilterRealUsers onChange={(onlyReal) => setRealOnly(onlyReal)}/>
        </div>
        <Typography sx={{mt:2}}>{ t('controlPanelUsersCount') + applyAllFilters(users).length}</Typography>
      </div>
      <List dense={true}>
        { 
            applyAllFilters(users).map(user => <UserListItem key={user.id} user={user} selection={filter} />) 
        }
      </List>
    </div>
  );

}

export default UsersList;