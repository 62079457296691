import React, { useRef, useState } from "react";
import styles from "./ConfirmationDetails.module.css"
import { Button, Paper, Typography, styled } from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import TranslationTypes from "../../../constants/translationTypes";
import REQUESTS from "../../../net/requests";
import { logAndReset } from "../../../utils/requestUtils";
import { useMutation } from "react-query";
import { masterDisplayName } from "../../../utils/formatUtils";
import { useTranslation } from "react-i18next";

const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 330,
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
  padding: theme.spacing(2),
  marginTop: 16,
  ...theme.typography.body2,
  textAlign: 'center',
}));

const ConfirmationDetails = ({ details, code, last, error }) => {
  const lang = useSelector(state => state.lang).title.toLowerCase();
  const [isConfirmed, setConfirmed] = useState(null);
  const { t } = useTranslation();
  const findText = (type) => details.translations.find(v => v.type === type && v.lang === lang)?.text ?? ""
  
  const confirmMutation = useMutation(REQUESTS.postConfirmationResult)

  if (error) {
    return (
      <div className={styles.box}>
        <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
          <div className={styles.cardBox}>
            <Typography variant="h6" sx={{ padding: 2 }}>{ t('codeConfirmationNoRecord') }</Typography>
          </div>
        </ProfilePaper>
      </div>
    );
  }

  const masterName = masterDisplayName(details.master, lang);
  const serviceTitle = `${findText(TranslationTypes.SERVICE_TITLE)} - ${findText(TranslationTypes.SERVICE_SUBTITLE)}`;

  const sendRequest = (confirm) => {
    const data = {
      code,
      last,
      confirm
    }
    confirmMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        setConfirmed(confirm);
        setTimeout(() => {
          confirmMutation.reset()
        }, 3000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(confirmMutation);
      }
    });
  }
  const handleAccept = () => {
    sendRequest(true)
  }
  const handleDelete = () => {
    sendRequest(false)
  }

  const card = <>
    <Typography variant="h4">{ dayjs(details.startTime).format("HH:mm") } - { dayjs(details.endTime).format("HH:mm") }</Typography>
    <Typography variant="h6">{ dayjs(details.startTime).format("DD.MM.YYYY") }</Typography> 
    <Typography sx={{ paddingTop: 1 }}>{ masterName }</Typography> 
    <Typography variant="h6" sx={{ paddingTop: 1, paddingBottom: 2 }}>{ serviceTitle }</Typography>
    <div className={styles.buttons}>
      <Button variant="contained" sx={{ borderRadius: 12, textTransform: 'none' }} onClick={handleAccept}>{ t('accept') }</Button>
      <Button variant="outlined" sx={{ borderRadius: 12, textTransform: 'none' }} onClick={handleDelete}>{ t('delete') }</Button>
    </div>
  </>
  return (
    <div className={styles.box}>
      <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
        <div className={styles.cardBox}>
          { isConfirmed === null && card }
          { isConfirmed === true && <Typography variant="h6" sx={{ padding: 2 }}>{ t('codeConfirmationAccepted') }</Typography> }
          { isConfirmed === false && <Typography variant="h6" sx={{ padding: 2 }}>{ t('codeConfirmationDeleted') }</Typography> }
        </div>
      </ProfilePaper>
    </div>
  );
}

export default ConfirmationDetails;