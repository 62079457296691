import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Grow, Paper, Popper, MenuItem, ClickAwayListener, MenuList, Fade } from "@mui/material";
import restService from "../../../net/RestService"
import signIn from "../../../redux/actionCreators/signIn";
import logout from "../../../redux/actionCreators/logout";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import URLS from '../../../constants/urls';
import saveMasterData from '../../../redux/actionCreators/saveMasterData';
import { loginUser } from '../../../utils/loginUtils';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import styles from "./AccountInfo.module.css"
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

const AccountInfo = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const queryClient = useQueryClient();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleGoogleLogin = async (event) => {
    const googleProvider = getAuth().GoogleAuthProvider();
    const firebaseResponse = await getAuth().signInWithPopup(googleProvider);
    const token = await firebaseResponse.user.getIdToken();
    await loginUser(dispatch, token, queryClient);
    console.log("login from google button")
    handleClose(event);
  };

  const handleLogout = async (event) => {
    await getAuth().signOut()
    restService.setToken(null)
    dispatch(logout());
    handleClose(event);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const user = useSelector((state) => state?.user);

  const handleProfileClick = () => {
    history.push(URLS.profileOf(user.id));
    setOpen(false);
  }

  const handleCodeClick = () => {
    history.push(URLS.INVITE_CODE);
    setOpen(false);
  }

  const handleLogin = () => {
    history.push(URLS.LOGIN);
    setOpen(false);
  }

  const handleRegister = () => {
    history.push(URLS.REGISTRATION);
    setOpen(false);
  }

  return (
    <>
      <div className={styles.avatarNoPhoto}>
        <IconButton 
          ref={anchorRef}
          aria-label="avatar" 
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle} 
        >
          <img src={ user?.avatar ? user.avatar : "/img/no_photo.png" } />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList 
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{ position: 'relative' }}
                  >
                    {
                      user ? 
                      <>
                        <MenuItem onClick={handleProfileClick}>{ t('accountInfoProfile') }</MenuItem>
                        <MenuItem onClick={handleCodeClick}>{ t('accountInfoCode') }</MenuItem>
                        <MenuItem onClick={handleLogout}>{ t('accountInfoLogout') }</MenuItem>
                      </> : 
                      <>
                        <MenuItem onClick={handleLogin}>{ t('accountInfoLogin') }</MenuItem>
                        <MenuItem onClick={handleRegister}>{ t('accountInfoRegister') }</MenuItem>
                      </>
                    }
                    
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </>
  );
};

export default AccountInfo;
