import styles from './ConfirmStatusWidget.module.css'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';

const ONE_DAY = 24 * 60 * 60 * 1000;

const ConfirmStatusWidget = ({ record }) => {
  return <div className={styles.box}>
    { record.hasMasterConfirmation ? <AlarmOnIcon color="success"/> : <AccessAlarmIcon /> }
    {/* <Typography>{ record.hasMasterConfirmation ? "Confirmed by master" : "Waiting for master confirmation" }</Typography> */}
  </div>;
}

export default ConfirmStatusWidget;