import * as React from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import REQUESTS from '../../../../net/requests';
import QUERY_KEYS from '../../../../net/query-keys';
import { logAndReset } from '../../../../utils/requestUtils';
import ServerError from '../../../widgets/ServerError/ServerError';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styles from "./ShowCodeDialog.module.css";
import { useTranslation } from 'react-i18next';
import AreYouSureDialog from '../../AreYouSureDialog';
import UserNameWidget from './UserNameWidget';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const ShowCodeDialog = ({ open, onClose, record }) => {

  const { t } = useTranslation();
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)
  const codeRequest = useQuery(QUERY_KEYS.generateAgreementCode(record.id), REQUESTS.generateAgreementCode(record.id), { refetchOnMount: true });

  const queryClient = useQueryClient();
  const resetMutation = useMutation(REQUESTS.resetAgreementCode())

  const response = codeRequest.data;

  const handleClose = () => {
    onClose();
  }

  const handleReset = () => {
    const data = {
      recordId: record.id
    }
    resetMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS.generateAgreementCode(record.id));
      },
      onError: () => {
        logAndReset(resetMutation);
      }
    });
  }

  const copyCode = (code) => {
    navigator.clipboard.writeText(code)
  }

  return (
    <BlurryDialog onClose={handleClose} open={open}  maxWidth="sm">
      <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
        { t('codeForClientsAgreement') }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0}}>
        <div>
          {
            codeRequest.isLoading ? <CircularProgress /> :
              codeRequest.isError ? <ServerError/> : 
                codeRequest.data.code !== null ?
                  <div className={styles.box}>
                    <Typography variant='h5'>{ t('code') }: { response.code }</Typography>
                    <IconButton onClick={ () => copyCode(response.code) }>
                      <ContentCopyIcon />
                    </IconButton>
                  </div> :
                  <>
                    <div className={styles.box}>
                      <Typography>{ t('inviteCodeDialogClientAccepted') }</Typography>
                      {
                        resetMutation.isLoading ? <CircularProgress /> :
                          resetMutation.isError ? <Typography>Error</Typography> :
                            <Button variant='outlined' onClick={() => setShowConfirmDialog(true)}>{ t('reset') }</Button>
                      }
                    </div>
                    <UserNameWidget userId={codeRequest.data.userId}/>
                  </>
          }
          {
            showConfirmDialog && <AreYouSureDialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)} onConfirm={handleReset} />
          }
        </div>
      </DialogContent>
    </BlurryDialog>
  );
};

export default ShowCodeDialog;
