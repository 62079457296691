import { Card, CircularProgress, styled, TextField, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import ServerError from "../../components/widgets/ServerError/ServerError";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import styles from "./CodeConfirmationPage.module.css"
import { useParams } from 'react-router';
import ConfirmationDetails from "./ConfirmationDetails";
import PageLayout from "../../components/layouts/PageLayout";
import { useTranslation } from "react-i18next";

const CODE_KEY = "code"
const LAST_KEY = "last"
const NOT_FOUND_ERROR_CODE = 72

const CodeConfirmationPage = ({ confirm, location }) => {
  const { id } = useParams();
  const code = new URLSearchParams(document.location.search).get(CODE_KEY)
  const last = Boolean(new URLSearchParams(document.location.search).get(LAST_KEY))
  const serviceRequest = useQuery(QUERY_KEYS.getServiceDetailsByCode(code), REQUESTS.getServiceDetailsByCode(code));
  const { t } = useTranslation();

  if (serviceRequest.isLoading) {
    return <CircularProgress />
  }
  const noRecord = serviceRequest.isError && serviceRequest.error.response.data.error.code === NOT_FOUND_ERROR_CODE
  if (serviceRequest.isError && !noRecord) {
    console.log("confirm error", serviceRequest);
    return <ServerError/>
  }

  const serviceDetails = serviceRequest.data;
  
  return (
    <PageLayout fullscreen={true} >
      <div className={styles.content}>
        <Typography align="center" variant="h5" sx={{ marginTop: 1 }}>{ t('codeConfirmationTitle') }</Typography>
        <ConfirmationDetails details={serviceDetails} code={code} last={last} error={noRecord}/>
      </div>
    </PageLayout>
  );
};

export default CodeConfirmationPage;
