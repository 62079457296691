import React, { useEffect, useRef, useState } from "react";
import ExpandMoreItem from "../ExpandMoreItem";
import { Avatar, Button, Card, Checkbox, CircularProgress, Divider, IconButton, ListItem, ListItemIcon, Menu, MenuItem, Select, TextField, Typography } from "@mui/material";
import Text from "../../Text";
import ConsentTypes from "../../../../constants/consentTypes";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ROLES from "../../../../constants/roles";
import styles from "./UserListItem.module.css"
import AddMasterDialog from "../../../dialogs/AddMasterDialog";
import { useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinkFakeUsersDialog from "../../../dialogs/LinkFakeUsersDialog";
import ConfirmDeleteFakeDialog from "../../../dialogs/ConfirmDeleteFakeDialog";
import EditFakeDialog from "../../../dialogs/EditFakeDialog";
import URLS from "../../../../constants/urls";
import { Link } from "react-router-dom";
import { userDisplayName } from "../../../../utils/formatUtils";

const UserListItem = ({ user, selection }) => {
  const currentUser = useSelector(state => state.user);
  
  const [masterDialogOpen, setMasterDialogOpen] = useState(false)
  const [showLinkDialog, setShowLinkDialog] = useState(false)
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)
  const [showEditFakeDialog, setShowEditFakeDialog] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState(null)

  const queryClient = useQueryClient();
  

  const handleCloseDialog = () => {
    setMasterDialogOpen(false);
    setShowLinkDialog(false);
    setShowConfirmDeleteDialog(false);
    setShowEditFakeDialog(false);
  }

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
    setMenuOpen(true);
  }

  const handleLinkToFake = () => {
    setShowLinkDialog(true);
    setMenuOpen(false);
  }

  const handleSetMaster = () => {
    setMasterDialogOpen(true);
    setMenuOpen(false);
  }

  const handleDeleteFake = () => {
    setShowConfirmDeleteDialog(true);
    setMenuOpen(false);
  }

  const handleEditFake = () => {
    setShowEditFakeDialog(true);
    setMenuOpen(false);
  }

  const canBeEdited = user.role === ROLES.USER && user.isFake === true && currentUser.role !== ROLES.USER;
  const canBeLinkedToFake = user.role === ROLES.USER && user.isFake === false && currentUser.role !== ROLES.USER;
  const canBeMaster = user.role === ROLES.USER && user.isFake === false && currentUser.role === ROLES.ADMIN;

  const showMenuButton = canBeLinkedToFake || canBeMaster || canBeEdited;

  const buildSelectableText = (text) => {
    const startPosition = text.toLowerCase().indexOf(selection.toLowerCase());
    const endPosition = startPosition + selection.length;
    return <div className={styles.userInfoSelectedText}>
        <Typography>{ text.substring(0, startPosition) }</Typography>
        <Typography backgroundColor={"#FA0"}>{ text.substring(startPosition, endPosition) }</Typography>
        <Typography>{ text.substring(endPosition, text.length) }</Typography>
      </div>
  }

  return (
    <div>
      <ListItem 
        sx={{ padding: 0, marginTop: 1, marginBottom: 1 }} 
        secondaryAction={
          showMenuButton && 
            <IconButton edge="end" onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
        }
      >
        <div className={styles.userItem}>
          <div className={styles.userAvatar}>
            <Avatar alt={user?.displayName} src={ user?.avatar ? user.avatar : "/img/no_photo.png" } sx={{ width: 36, height: 36 }} />
            
            { 
              user.role !== ROLES.USER &&
                <Text>{ user.role === ROLES.MODERATOR ? "(Master)" : "(Admin)" }</Text> 
            }
          </div>


          <div className={styles.userInfo}>
            <div className={styles.userInfoRow}>
              <Typography>id:</Typography>
              <Typography>{ user.id }</Typography>
            </div>
            <div className={styles.userInfoRow}>
              <Typography>email:</Typography>
              {
                selection === "" || !user.email || user.email.toLowerCase().indexOf(selection.toLowerCase()) === -1 ?
                  <Typography>{ user.email }</Typography> :
                  buildSelectableText(user.email)
              }
            </div>
            <div className={styles.userInfoRow}>
              <Typography>name:</Typography>
              <Link to={URLS.profileOf(user.id)}>
                {
                  selection === "" || userDisplayName(user, false).toLowerCase().indexOf(selection.toLowerCase()) === -1 ?
                  <Typography>{ userDisplayName(user, false) }</Typography> :
                    buildSelectableText(userDisplayName(user, false))
                }
              </Link>
            </div>
          </div>

          {
            showMenuButton &&
              <Menu
                id="basic-menu"
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                { canBeLinkedToFake && <MenuItem onClick={handleLinkToFake}>Link to Fake User</MenuItem> }
                { canBeMaster && <MenuItem onClick={handleSetMaster}>Set as master</MenuItem> }
                { canBeEdited && <MenuItem onClick={handleEditFake}>Edit</MenuItem> }
                { canBeEdited && <MenuItem onClick={handleDeleteFake}>Delete user</MenuItem> }
              </Menu>
          }

          {
            showLinkDialog && <LinkFakeUsersDialog open={showLinkDialog} onClose={handleCloseDialog} targetUser={user} />
          }

          {
            showConfirmDeleteDialog && <ConfirmDeleteFakeDialog open={showConfirmDeleteDialog} onClose={handleCloseDialog} targetUser={user} />
          }

          {
            showEditFakeDialog && <EditFakeDialog open={showEditFakeDialog} onClose={handleCloseDialog} targetUser={user} />
          }
          
        </div>
      </ListItem>
      <Divider light />
      
      <AddMasterDialog selectedUser={user} open={masterDialogOpen} onClose={handleCloseDialog}/>
    </div>
  );
}

export default UserListItem;