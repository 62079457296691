import React from "react";
import { useSelector } from "react-redux";
import QUERY_KEYS from "../../../../../net/query-keys";
import REQUESTS from "../../../../../net/requests";
import { Avatar, CircularProgress, Typography } from "@mui/material";
import ServerError from "../../../../widgets/ServerError/ServerError";
import { useQuery } from "react-query";
import { userDisplayName } from "../../../../../utils/formatUtils";
import { Link } from "react-router-dom";
import URLS from "../../../../../constants/urls";
import styles from "./UserNameWidget.module.css";

const UserNameWidget = ({ userId }) => {
  const userRequest = useQuery(QUERY_KEYS.getUser(userId), REQUESTS.getUserProfile(userId), { refetchOnMount: true });


  return (
    <div>
      {
            userRequest.isLoading ? <CircularProgress /> :
              userRequest.isError ? <ServerError/> : 
              <div className={styles.box}>
                <Avatar alt={userRequest.data.displayName} src={userRequest.data.avatar} sx={{ width: 36, height: 36 }} />
                <Link to={URLS.profileOf(userRequest.data.id)}>{ userDisplayName(userRequest.data, true)}</Link>
              </div>
      }
    </div>
  );
};

export default UserNameWidget;