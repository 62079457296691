import * as React from 'react';
import "./ConsentBlockItem.css";
import { Checkbox } from "@mui/material";
import Text from '../../widgets/Text';
import { red } from '@mui/material/colors';
import ConsentTypes from '../../../constants/consentTypes';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useSelector } from 'react-redux';
import TranslationTypes from '../../../constants/translationTypes';
import URLS from '../../../constants/urls';

const ConsentBlockItem = ({ policyRef, error, consent, translations }) => {
  const [accepted, setAccepted] = React.useState(policyRef.current[consent.key] === true ? true : false)

  const lang = useSelector(state => state.lang)
  const translationItem = translations.find(item => item.lang === lang.title.toLowerCase() && item.type === TranslationTypes.CONSENT_TITLE)
  let translation = ""
  if (translationItem) {
    translation = translationItem.text
  }

  let text
  const startIndex = translation.indexOf('[')
  const endIndex = translation.indexOf(']')
  console.log(`startIndex === ${startIndex}, endIndex === ${endIndex} translation: ${lang.title.toLowerCase()}`, consent, translations);
  if (startIndex === -1 || endIndex === -1) {
    text = <Text>{ (consent.required === true ? "* " : "") + translation }</Text>
  } else {
    text = <Text>{ (consent.required === true ? "* " : "") + translation.substring(0, startIndex) }<a href={URLS.consentPage(consent.id)} target="_blank">{ translation.substring(startIndex + 1, endIndex) }</a>{ translation.substring(endIndex + 1) }</Text>
  }

  const handleChange = (event) => {
    policyRef.current = { ...policyRef.current, [consent.key]: event.target.checked }
    setAccepted(event.target.checked)
  }

  return (
    <div className='consents-block-row'>
      <Checkbox sx={ error[consent.key] === true ? { color: red[800] } : {} } checked={accepted} onChange={handleChange}/>
      { text }
    </div>
  );
};

export default ConsentBlockItem;
