import { Button, Card, CircularProgress, Paper, styled, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import ServerError from "../../components/widgets/ServerError/ServerError";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import { useParams } from 'react-router';
import { useSelector } from "react-redux";
import TranslationTypes from "../../constants/translationTypes";
import PageLayout from "../../components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { logAndReset } from "../../utils/requestUtils";
import dayjs from "dayjs";
import ConsentsBlock from "../../components/dialogs/ConsentsBlock";
import styles from "./InviteCodePage.module.css";
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import SERVER_CODES from "../../net/codes";
import OtpInput from 'react-otp-input';

const InviteCodePage = () => {
  const user = useSelector((state) => state?.user);
  const { t } = useTranslation();
  const [codeValue, setCodeValue] = useState("")
  const [record, setRecord] = useState(null);
  const [result, setResult] = useState(null);
  const [error, setError] = useState("");
  const [policyError, setPolicyError] = useState({});
  const policyRef = useRef({});

  const mutation = useMutation(REQUESTS.checkRecordCode());
  const acceptMutation = useMutation(REQUESTS.acceptRecordCode());
  const maxW500 = useMediaQuery('(max-width:500px)');

  const onCodeChanged = (code) => {
    //setCodeValue(code.replace(/\D/g,'0'))
    setCodeValue(code)
    setError("")
  }

  const handleCheck = () => {
    const data = {
      code: codeValue
    }
    mutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log(`Code is received!`)
        setRecord(data.record)
        setTimeout(() => {
          mutation.reset();
        }, 1000)
        
      },
      onError: (error, variables, context) => {
        if (error.response?.data?.error?.code === SERVER_CODES.NOT_FOUND) {
          setError(t('codeNotFound'))
        }
        console.error(`onError:`, JSON.stringify(error.response ?? error))
        logAndReset(mutation);
      }
    });
  }

  const handleAccept = () => {
    let keys = Array.from( Object.keys(policyRef.current) );
    const badKeys = keys.filter(key => policyRef.current[key].checked === false && policyRef.current[key].required === true)
    badKeys.forEach(key => {
      setPolicyError(prev => ({ ...prev, [key]: true}))
    })
    if (badKeys.length > 0) {
      return;
    }
    const data = {
      code: codeValue,
      recordId: record.id
    }

    acceptMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log(`success!`)
        setResult(true)
        setTimeout(() => {
          acceptMutation.reset();
        }, 1000)
        
      },
      onError: (error, variables, context) => {
        console.error(`onError:`, JSON.stringify(error.response ?? error))
        logAndReset(acceptMutation);
        
      }
    });
  }

  const size = maxW500 ? '36px' : '48px';
  const codeCard = <>
    <Typography variant="h5" align="center" sx={{mb:2, mt:2}}>{ t('invitePageEnterCodeTitle') }</Typography>
    <div className={styles.codeRow}>
      <div className={styles.codeNumbers}>
        <OtpInput
          value={codeValue}
          inputType="text"
          onChange={onCodeChanged}
          numInputs={6}
          inputStyle={styles.character}
          inputFocusStyle={styles.characterFocused}
          renderInput={(props) => <input {...props} style={{width: size, height: size}}/>}
        />
      </div>
      { 
        mutation.isLoading ? <CircularProgress sx={{ marginRight: 1 }} /> : 
          mutation.isSuccess ? <Typography sx={{ marginRight: 1 }}>{ t('done') }</Typography> :
            <Button variant='outlined' disabled={codeValue.length < 6} onClick={handleCheck}>{ t('check') }</Button> 
      }
    </div>
    <Typography align="center" sx={{mt:2}}>{ error }</Typography>
  </>

  const recordCard = (record) => {
    const time = `${dayjs(record.startTime).format("HH:mm")} - ${dayjs(record.endTime).format("HH:mm")}`
    return <>
      <Typography align="center" sx={{mb:2}}>{ t('inviteCodePageYourRecord') }: { time }, {dayjs(record.startTime).format("DD/MM/YYYY")}</Typography>
      <ConsentsBlock policyRef={policyRef} error={policyError}/>
      <div className={styles.buttons}>
        <Button variant="outlined" onClick={handleAccept}>{ t('accept') }</Button>
        <Button variant="outlined" onClick={() => {setRecord(null); setCodeValue("");}}>{ t('cancel') }</Button>
      </div>
    </>
  }

  
  const resultCard = <div className={styles.doneBox}>
    <CloudDoneIcon color="secondary" sx={{width: '36px', height: '36px'}}/>
    <Typography>{ t('done') }!</Typography>
  </div>

  var content
  if (record) {
    content = recordCard(record);
  } else {
    content = codeCard
  }

  return (
    <PageLayout fullscreen={true}>
      <div className={styles.content}>
        <Paper sx={{p:2}}>
          {
            result ? resultCard :
              user && content
          }
        </Paper>
      </div>
    </PageLayout>
  );
};

export default InviteCodePage;
