import * as React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const AreYouSureDialog = ({ open, onClose, onConfirm }) => {

  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  }

  const handleDelete = () => {
    if (onConfirm) onConfirm();
    onClose();
  }

  return (
    <BlurryDialog onClose={handleClose} open={open}  maxWidth="sm">
      <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
        { t('profileConfirmation') }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0}}>
        <div>
          <Typography sx={{marginBottom: 2}}>{ t('areYouSure') }</Typography>
          <div>
            <Button variant="outlined" onClick={handleDelete}>{ t('yes') }</Button>
            <Button variant="outlined" onClick={handleClose} sx={{ marginLeft: 2}}>{ t('cancel') }</Button>
          </div>
        </div>
      </DialogContent>
    </BlurryDialog>
  );
};

export default AreYouSureDialog;
