import * as React from 'react';
import { Avatar, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import REQUESTS from '../../../net/requests';
import styles from "./MoveRecordDialog.module.css"
import ServerError from '../../widgets/ServerError/ServerError';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QUERY_KEYS from '../../../net/query-keys';
import { buildAvailableTime } from '../OrderDialog';
import { useState } from 'react';
import SelectTimePage from '../OrderDialog/pages/SelectTimePage';
import { logAndReset } from '../../../utils/requestUtils';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const MoveRecordDialog = ({ open, onClose, recordId, record, scheduleId }) => {
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.user);
  const [selected, setSelected] = useState();

  const moveMutation = useMutation(REQUESTS.moveRecord());
  const queryClient = useQueryClient();
  const bookItemsRequest = useQuery(QUERY_KEYS.BOOK_ITEMS, REQUESTS.getBookItems(), { refetchOnMount: true });

  if (bookItemsRequest.isLoading) {
    return <CircularProgress />
  }
  if (bookItemsRequest.isError) {
    return <ServerError/>
  }

  const fixSlotForMovement = (slot) => {
    // console.log("fixSlot:");
    // console.log("slot.startTime:", dayjs(slot.startTime), ", slot.endTime: ", dayjs(slot.endTime));
    // console.log("record startTime:", dayjs(record.startTime), ", record.endTime: ", dayjs(record.endTime));
    const startTime = record.startTime;
    const endTime = record.endTime;
    if (startTime <= slot.startTime && endTime >= slot.endTime) {
      // console.log("fixSlot returns: null");
      return null;
    }
    if (startTime <= slot.startTime && (endTime > slot.startTime && endTime < slot.endTime)) {
      return {
        masterId: slot.masterId,
        startTime: endTime,
        endTime: slot.endTime
      };
    }
    if (startTime > slot.startTime && startTime < slot.endTime && endTime < slot.endTime) {
      return [
        {
          masterId: slot.masterId,
          startTime: slot.startTime,
          endTime: startTime
        },
        {
          masterId: slot.masterId,
          startTime: endTime,
          endTime: slot.endTime
        },
      ];
    }
    if (startTime > slot.startTime && startTime < slot.endTime && endTime >= slot.endTime) {
      return {
        masterId: slot.masterId,
        startTime: slot.startTime,
        endTime: startTime
      };
    }
    return slot;
  }

  const bookItems = { 
    data: { 
      ...bookItemsRequest.data, 
      masterSlots: bookItemsRequest.data.masterSlots.filter(v => v.masterId === record.masterId),
      busySlots: bookItemsRequest.data.busySlots.filter(v => v.masterId === record.masterId).flatMap(v => fixSlotForMovement(v)).filter(v => v !== null)
    } 
  };
  // bookItems.data.busySlots.forEach(item => console.log("result busy slot:", dayjs(item.startTime), ", end:", dayjs(item.endTime)))

  const handleMove = () => {
    const data = {
      id: recordId,
      time: selected
    };
    moveMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS.BOOK_ITEMS);
        queryClient.invalidateQueries(QUERY_KEYS.recordsOfSchedule(scheduleId));
        setTimeout(() => {
          onClose();
        }, 1000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(moveMutation);
      }
    });
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <BlurryDialog onClose={handleClose} open={open} maxWidth="sm">
      <DialogTitle sx={{ m: 0, paddingRight:7 }}>
        { t('newTimeForRecord') }
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0}}>
        <div>
          <SelectTimePage build={() => buildAvailableTime(bookItems, [], record.masterId, record.serviceId)} onClick={(time) => setSelected(time)} selected={selected}/>
          {
            moveMutation.isLoading ? <CircularProgress /> :
              moveMutation.isError ? <Typography>Error</Typography> :
                moveMutation.isSuccess ? <Typography>Done!</Typography> :
                  <div className={styles.moveButton}><Button variant='outlined' onClick={handleMove} sx={{m:2}}>{ t('newTimeMoveButton') }</Button></div>
          }
        </div>
      </DialogContent>
    </BlurryDialog>
  );
};

export default MoveRecordDialog;
