

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Badge, Box, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FilterRealUsers = ({ onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = (onlyReal) => {
    setAnchorEl(null);
    setSelected(onlyReal);
    if (onChange) {
      onChange(onlyReal)
    }
  };

  return <>
    <IconButton variant="outlined" onClick={handleClick}>
      <Badge color="secondary" variant="dot" invisible={selected === null}>
        <FilterAltIcon />
      </Badge>
    </IconButton>
    <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => handleClose(null)} selected={selected === null}>{ t('filterRealUsersAll') }</MenuItem>
        <MenuItem onClick={() => handleClose(true)} selected={selected === true}>{ t('filterRealUsersReal') }</MenuItem>
        <MenuItem onClick={() => handleClose(false)} selected={selected === false}>{ t('filterRealUsersFake') }</MenuItem>
      </Menu>

  </>
}

export default FilterRealUsers;