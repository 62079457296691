
const QUERY_KEYS = {
    AUTH: `auth`,
    SERVICES: `services`,
    MASTERS: `masters`,
    PROFILE: `profile`,
    MASTER_SCHEDULES: `schedules`,
    RECORDS: `records`,
    ALL_MASTERS_SCHEDULES: `masters/schedules`,
    CONSENTS: `consents`,
    CATEGORIES: `categories`,
    CATEGORIES_ALL: `categories/all`,
    USERS: `users`,
    QUESTIONS: `questions`,
    ANSWERS: `answers`,
    FEATURED_SERVICES: `featured_services`,
    RISKS: `risks`,
    GENERAL_ANALYTICS: `analytics/general`,
    POPULAR_ANALYTICS: `analytics/popular`,
    MASTERS_ANALYTICS: `analytics/masters`,
    BOOK_ITEMS: `book_items`,
    MASTER_NOTES: `notes`,
    PRIVACY_POLICY_LINK: `privacy_policy_link`,
    FEEDBACKS: `feedbacks`,
    SETTINGS: `settings`,
    
    getUserProfile: (userId) => `${QUERY_KEYS.PROFILE}/${userId}`,
    getMasterSchedules: (masterId) => `${QUERY_KEYS.MASTER_SCHEDULES}/${masterId}`,
    getMastersForService: (serviceId) => `${QUERY_KEYS.MASTERS}/for/${serviceId}`,
    getMasterService: (masterId, serviceId) => `${QUERY_KEYS.MASTERS}/${masterId}/services/${serviceId}`,
    getMasterServices: (masterId) => `${QUERY_KEYS.MASTERS}/${masterId}/services`,
    findFreeSlots: (masterId, serviceId) => `${QUERY_KEYS.RECORDS}/masters/${masterId}/services/${serviceId}`,
    recordsOfSchedule: (scheduleId) => `${QUERY_KEYS.RECORDS}/schedules/${scheduleId}`,
    recordsOfSchedules: (scheduleIds) => `${QUERY_KEYS.RECORDS}/schedules/for/${scheduleIds}`,
    getConsentWithText: (consentId) => `${QUERY_KEYS.CONSENTS}/texts/${consentId}`,
    getConsentForType: (type) => `${QUERY_KEYS.CONSENTS}/textsForType/${type}`,
    getServicesForCategory: (categoryId) => `${QUERY_KEYS.SERVICES}/forCategory/${categoryId}`,
    getServiceCategories: (serviceId) => `${QUERY_KEYS.SERVICES}/${serviceId}/categories`,
    getConsent: (consentId) => `${QUERY_KEYS.CONSENTS}/${consentId}`,
    getAnswersState: (userId) => `${QUERY_KEYS.ANSWERS}/state/${userId}`,
    getQuestionnaire: (userId) => `${QUERY_KEYS.ANSWERS}/questionnaire/${userId}`,
    getService: (serviceId) => `${QUERY_KEYS.SERVICES}/${serviceId}`,
    risks: (serviceId) => `${QUERY_KEYS.RISKS}/${serviceId}`,
    getUserRecords: (userId) => `${QUERY_KEYS.RECORDS}/forUser/${userId}`,
    getUserSettings: (userId) => `${QUERY_KEYS.PROFILE}/${userId}/settings`,
    getMasterSettings: (masterId) => `${QUERY_KEYS.MASTERS}/${masterId}/settings`,
    getGeneralAnalytics: (start, end) => `${QUERY_KEYS.GENERAL_ANALYTICS}/${start}/${end}`,
    getUnconfirmedRecords: (masterId) => `${QUERY_KEYS.RECORDS}/unconfirmed/${masterId}`,
    getConfirmationResult: (code, confirm, last) => `${QUERY_KEYS.RECORDS}/confirmation/${code}/${confirm}/${last}`,
    getServiceDetailsByCode: (code) => `${QUERY_KEYS.RECORDS}/details/${code}`,
    getUserHistory: (userId) => `${QUERY_KEYS.RECORDS}/history/${userId}`,
    getMasterNote: (profileId) => `${QUERY_KEYS.MASTER_NOTES}/forUser/${profileId}`,
    getUser: (userId) => `${QUERY_KEYS.AUTH}/users/${userId}`,
    getPopularAnalytics: (start, end) => `${QUERY_KEYS.POPULAR_ANALYTICS}/${start}/${end}`,
    getMastersAnalytics: (start, end) => `${QUERY_KEYS.MASTERS_ANALYTICS}/${start}/${end}`,
    getFeedbacks: () => `${QUERY_KEYS.FEEDBACKS}/`,
    getNewFeedbacks: () => `${QUERY_KEYS.FEEDBACKS}/new`,
    generateAgreementCode: (id) => `${QUERY_KEYS.RECORDS}/codes/generate_${id}`,
  }
  
  export default QUERY_KEYS;