import React, { useState } from "react";
import styles from "./UserSelector2.module.css";
import { Button, IconButton, TextField, Typography } from '@mui/material';
import SelectUserDialog from "../../dialogs/SelectUserDialog";
import CreateFakeUserDialog from "../../dialogs/CreateFakeUserDialog";
import { userDisplayName } from "../../../utils/formatUtils";
import PersonIcon from '@mui/icons-material/Person';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddNoteDialog from "../../dialogs/AddNoteDialog";

const UserSelector2 = ({ user, onChange, note, onNoteChange, onClose }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [showFakeDialog, setShowFakeDialog] = useState(false);
  const [showNoteDialog, setShowNoteDialog] = useState(false);

  const handleShowDialog = () => {
    setShowDialog(true)
  }

  const handleChange = (user) => {
    setShowDialog(false)
    onChange(user)
    console.log("qwe", user);
  }

  const handleCloseDialog = (closeAll) => {
    setShowDialog(false)
    if (closeAll && onClose) {
      onClose();
    }
  }

  return (
    <>
      <div className={styles.box}>
        {
          user && 
            <div className={styles.selectBox}>
              <Typography>{ userDisplayName(user, true) }</Typography>
            </div>
        }

        <IconButton aria-label="select" onClick={ handleShowDialog }>
          <PersonIcon />
        </IconButton>
        <IconButton aria-label="note" onClick={ () => setShowNoteDialog(true) }>
          <NoteAddIcon />
        </IconButton>
        <IconButton aria-label="create" onClick={ () => setShowFakeDialog(true) }>
          <AddCircleOutlineIcon />
        </IconButton>
        {
          showDialog && <SelectUserDialog open={showDialog} onClose={handleCloseDialog} onChange={handleChange}/>
        }
        {
          showNoteDialog && <AddNoteDialog open={showNoteDialog} onClose={() => setShowNoteDialog(false)} note={note} onChange={onNoteChange} /> 
        }
        {
          showFakeDialog && <CreateFakeUserDialog open={showFakeDialog} onClose={() => setShowFakeDialog(false)} onChange={handleChange}/>
        }
      </div>
      {
        note && <Typography sx={{ pb: 2 }}>Пометка: { note }</Typography>
      }
    </>
  );
};

export default UserSelector2;