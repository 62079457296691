import { CircularProgress, ListItem, Typography, useMediaQuery } from "@mui/material";
import React, { useRef, useState } from "react";
import styles from "./UserRecord.module.css"
import TimeWidget from "../../../../components/widgets/profile/TimeWidget";
import ServiceWidget from "../../../../components/widgets/profile/ServiceWidget";
import MasterWidget from "../../../../components/widgets/profile/MasterWidget";
import ConfirmStatusWidget from "./ConfirmStatusWidget";
import UserConfirmInfoWidget from "../../UnconfirmedRecords/UnconfirmedRecord/UserConfirmInfoWidget";
import REQUESTS from "../../../../net/requests";
import { useMutation, useQueryClient } from "react-query";
import { logAndReset } from "../../../../utils/requestUtils";
import ConfirmActionWidget from "../../../../components/widgets/profile/ConfirmActionWidget";
import QUERY_KEYS from "../../../../net/query-keys";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import ConsentsBlock from "../../../../components/dialogs/ConsentsBlock";

const ONE_DAY = 24 * 60 * 60 * 1000;

const UserRecord = ({ record }) => {
  
  const [showDialog, setShowDialog] = useState(false);
  const [policyError, setPolicyError] = useState({});
  const policyRef = useRef({});
  const { t } = useTranslation();

  const removeMutation = useMutation(REQUESTS.removeRecord(record.id))
  const confirmMutation = useMutation(REQUESTS.confirmRecordByClient(record.id))
  const queryClient = useQueryClient();

  const handleDelete = () => {
    removeMutation.mutate({}, {
      onSuccess: (data, error, variables, context) => {
        console.log(`Removed!`);
        
        setTimeout(() => {
          queryClient.invalidateQueries(QUERY_KEYS.getUserRecords(record.clientId));
          removeMutation.reset()
        }, 3000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(removeMutation);
      }
    });
  }

  const handleConfirm = () => {
    let keys = Array.from( Object.keys(policyRef.current) );
    const badKeys = keys.filter(key => policyRef.current[key].checked === false && policyRef.current[key].required === true)
    badKeys.forEach(key => {
      setPolicyError(prev => ({ ...prev, [key]: true}))
    })
    if (badKeys.length > 0) {
      return;
    }

    const data = {
      agree: true
    }

    confirmMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        setTimeout(() => {
          queryClient.invalidateQueries(QUERY_KEYS.getUserRecords(record.clientId));
          confirmMutation.reset()
        }, 3000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(confirmMutation);
      }
    });
  }

  const askConfirmation = (!record.hasInitUserConfirmation) || (!record.hasFinalUserConfirmation && record.startTime - Date.now() <= ONE_DAY)

  return (
    <ListItem sx={{ padding: 0, marginTop: 1 }} alignItems="center">
      <div className={styles.box}>
        <div className={styles.row}>
          <ServiceWidget record={record} withPhoto={true} />
          <div className={styles.timeAndStatus}>
            <TimeWidget record={record} />
            <ConfirmStatusWidget record={record} />
          </div>
          <MasterWidget record={record} />
          { askConfirmation && <ConsentsBlock policyRef={policyRef} error={policyError}/> }
          {
            confirmMutation.isLoading || confirmMutation.isSuccess ? <CircularProgress /> :
              <ConfirmActionWidget record={record} onConfirm={handleConfirm} onDelete={() => setShowDialog(true)} askConfirmation={askConfirmation} showTitle={false} />
          }
          {
            removeMutation.isLoading ? <Typography>Loading...</Typography> : 
              removeMutation.isError ? <Typography>Error: { removeMutation.error.response.status === 403 ? removeMutation.error.response.data.error.message : removeMutation.error.message}</Typography> : 
                removeMutation.isSuccess ? <Typography>Done!</Typography> : 
                  showDialog && <div><ConfirmationDialog open={showDialog} onClose={() => setShowDialog(false)} text={ t('profileDeleteRecordTitle') } onConfirm={handleDelete} /></div>
          }
          
        </div>
      </div>
    </ListItem>
  );
};

export default UserRecord;
