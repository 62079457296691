import * as React from 'react';
import styles from "./ScheduleRecordsItem.module.css";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Grow, Paper, Popper, MenuItem, ClickAwayListener, MenuList, Fade, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText, DialogContent, CircularProgress, Button, Divider, styled, Typography } from "@mui/material";

import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import URLS from '../../../constants/urls';
import dayjs, { Dayjs } from 'dayjs';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ServerError from '../../widgets/ServerError/ServerError';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import RecordNote from './RecordNote';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import RecordNoteDialog from './RecordNote/RecordNoteDialog';
import { useTranslation } from 'react-i18next';
import Filter6Icon from '@mui/icons-material/Filter6';
import ShowCodeDialog from './ShowCodeDialog';
import MoveRecordDialog from '../MoveRecordDialog';
import { useState } from 'react';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const ScheduleRecordsItem = ({ record, client, service, scheduleId, recordNotes }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state?.user);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false)
  const [selectedTime, setSelectedTime] = React.useState()
  const [master, setMaster] = React.useState()
  const [policyError, setPolicyError] = React.useState(false)
  const [recordError, setRecordError] = React.useState()
  const policyRef = React.useRef()
  const [showNoteDialog, setShowNoteDialog] = React.useState(false)
  const [showCodeDialog, setShowCodeDialog] = React.useState(false)
  const [showMoveRecordDialog, setShowMoveRecordDialog] = useState(false)
  const { t } = useTranslation();
  
  const notes = recordNotes.sort((a, b) => {
    if (a.userId === user.id) {
      return -1
    }
    if (b.userId === user.id) {
      return 1
    }
    return a.id - b.id;
  });

  return <div>
    <div className={styles.scheduleRecordItem}>
      { record.endTime > Date.now() && <Button variant='outlined' onClick={() => setShowConfirmationDialog(true)}>{ t('delete') }</Button> }
      <Typography>{dayjs(record.startTime).format("HH:mm") + ' - ' + dayjs(record.endTime).format("HH:mm")}</Typography>
      <div><a href={URLS.profileOf(client.id)} target="_blank">{ client.isFake ? client.fakeName + ' [fake]' : (client.displayName && client.displayName.length > 0 ? client.displayName : client.email) }</a></div>
      <div className={styles.options}>
        <IconButton onClick={ () => setShowCodeDialog(true) }>
          <Filter6Icon />
        </IconButton>
        {
          notes.length === 0 && 
            <IconButton aria-label="select" onClick={ () => setShowNoteDialog(true) }>
              <NoteAddIcon />
            </IconButton>
        }
        <IconButton onClick={ () => setShowMoveRecordDialog(true) }>
          <SwapVertIcon />
        </IconButton>
      </div>
      
      {
        showNoteDialog && <RecordNoteDialog open={showNoteDialog} onClose={() => setShowNoteDialog(false)} recordId={record.id} scheduleId={scheduleId} />
      }
      {
        showConfirmationDialog && <DeleteConfirmationDialog open={showConfirmationDialog} onClose={() => setShowConfirmationDialog(false)} record={record} scheduleId={scheduleId} />
      }
      {
        showCodeDialog && <ShowCodeDialog open={showCodeDialog} onClose={() => setShowCodeDialog(false)} record={record} />
      }
      {
        showMoveRecordDialog && <MoveRecordDialog open={showMoveRecordDialog} onClose={() => setShowMoveRecordDialog(false)} recordId={record.id} record={record} scheduleId={scheduleId} />
      }
    </div>

    { 
      notes.length > 0 && <div className={styles.recordNotes}>
        { notes.map(note => <RecordNote key={note.id} note={note} recordId={record.id} scheduleId={scheduleId} />) }
      </div>
    }
  </div>
};

export default ScheduleRecordsItem;
