import { Avatar, Button, CircularProgress, Paper, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import styles from "./UserProfile.module.css"
import { useParams } from 'react-router';
import { useSelector } from "react-redux";
import URLS from "../../../constants/urls";
import { useHistory } from "react-router-dom";
import EditProfileDialog from "../../../components/dialogs/EditProfileDialog";
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import ROLES from "../../../constants/roles";
import MasterNotes from "./MasterNotes";
import { sourcePlatformName } from "../../../constants/sourcePlatform";

const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 520,
  width: "100%",
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: 36,
  marginTop: 16,
  ...theme.typography.body2,
  textAlign: 'center',
  borderRadius: 20
}));

const UserProfile = ({ profile }) => {
  const user = useSelector((state) => state?.user);
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);

  const openQuestionnaire = () => {
    if (user) {
      history.push(URLS.questionnairePage(profile.id))
    }
  }

  const normalTextSx = { fontSize: 26 }
  const emptyTextSx = { fontSize: 26, color: "#999999" }

  const normalEmailSx = { fontSize: 16 }
  const emptyEmailSx = { fontSize: 16, color: "#999999" }

  const isModerator = user.role !== ROLES.USER
  return (
    <div className={styles.box}>
      <ProfilePaper>
        <div className={styles.header}>
          <Avatar alt={profile?.displayName} src={profile?.avatar} sx={{ width: 112, height: 112 }} />

          <div className={styles.info}>
            <Typography sx={profile?.displayName ? normalTextSx : emptyTextSx}>{ profile?.displayName ?? "Empty Name" }</Typography>
            <Typography sx={profile?.email ? normalEmailSx : emptyEmailSx}>{ profile?.email ?? "Empty Email" }</Typography>
            <Button variant="contained" sx={{ borderRadius: 12, marginTop: 1, textTransform: 'none', }} onClick={() => setShowEditProfileDialog(true)}>{ t('profileEditTitle') }</Button>
          </div>
        </div>

        <div className={styles.questionnaire}>
          <Typography sx={profile?.email ? normalEmailSx : emptyEmailSx}>{ t('profileQuestionnaire') }</Typography>
          <Button variant="contained" sx={{ borderRadius: 12, textTransform: 'none' }} onClick={openQuestionnaire}>{ profile.id === user.id ? t('profileNoteEdit') : t('profileQuestionnaireShow') }</Button>
        </div>

        {
          isModerator && profile && profile.id && <MasterNotes profile={profile} />
        }
        {
          isModerator && <Typography sx={{m:1}}>{ t('source') }: { t(sourcePlatformName(profile.sourcePlatform)) }</Typography>
        }

        { showEditProfileDialog && <EditProfileDialog open={showEditProfileDialog} onClose={() => setShowEditProfileDialog(false)} profile={profile}/> }
      </ProfilePaper>
    </div>
  );
};

export default UserProfile;
