import ENDPOINTS from "./endpoints";
import restClient from "./RestService";

const REQUESTS = {
    getUserProfile: (userId) => () => restClient.get(ENDPOINTS.getUserProfile(userId)).then((res) => res.data),
    getServices: () => restClient.get(ENDPOINTS.SERVICES).then((res) => res.data),
    getMasters: () => restClient.get(ENDPOINTS.MASTERS).then((res) => res.data),
    editMaster: async (data) => {
      if (data.file) {
        const formData = new FormData();
        formData.append('file', data.file, data.file.name);
        const fileResponse = await restClient.multipartPost(ENDPOINTS.UPLOAD_IMAGE, formData).then((res) => res.data);
  
        data.file = undefined;
        data.fileId = fileResponse.smallFileId;
      }
      return await restClient.put(ENDPOINTS.editMaster(data.id), data).then((res) => res.data);
    },
    removeMaster: (masterId) => () => restClient.delete(ENDPOINTS.deleteMaster(masterId)).then((res) => res.data),
    getCategories: () => restClient.get(ENDPOINTS.CATEGORIES).then((res) => res.data),
    getAllCategories: () => restClient.get(ENDPOINTS.CATEGORIES_ALL).then((res) => res.data),
    getProfile: () => restClient.get(ENDPOINTS.PROFILE).then((res) => res.data),
    postTimeFrame: (data) => restClient.post(ENDPOINTS.TIME_FRAME, data).then((res) => res.data),
    addSchedule: (data) => restClient.post(ENDPOINTS.ADD_SCHEDULE, data).then((res) => res.data),
    changeSchedule: (masterId, scheduleId) => (data) => restClient.put(ENDPOINTS.changeSchedule(masterId, scheduleId), data).then((res) => res.data),
    removeSchedule: ({ masterId, scheduleId }) => restClient.delete(ENDPOINTS.removeSchedule(masterId, scheduleId)).then((res) => res.data),
    getSchedules: (masterId) => () => restClient.get(ENDPOINTS.getSchedules(masterId)).then((res) => res.data),
    getMastersForService: (serviceId) => () => restClient.get(ENDPOINTS.getMastersForService(serviceId)).then((res) => res.data),
    getMasterService: (masterId, serviceId) => () => restClient.get(ENDPOINTS.getMasterService(masterId, serviceId)).then((res) => res.data),
    deleteMasterService: (data) => restClient.post(ENDPOINTS.REMOVE_MASTER_SERVICE, data).then((res) => res.data),
    findFreeSlots: (masterId, serviceId, startDate, endDate) => () => restClient.get(ENDPOINTS.findFreeSlots(masterId, serviceId, startDate, endDate)).then((res) => res.data),
    addRecord: (data) => restClient.post(ENDPOINTS.RECORDS, data).then((res) => res.data),
    removeRecord: (recordId) => () => restClient.delete(ENDPOINTS.removeRecord(recordId)).then((res) => res.data),
    getAllSchedules: () => restClient.get(ENDPOINTS.ALL_SCHEDULES).then((res) => res.data),
    getRecordsOfSchedule: (scheduleId) => () => restClient.get(ENDPOINTS.getRecordsOfSchedule(scheduleId)).then((res) => res.data),
    getRecordsOfSchedules: (scheduleIds) => async () => {
      const result = []
      for (let index = 0; index < scheduleIds.length; index++) {
        const id = scheduleIds[index];
        const response = await restClient.get(ENDPOINTS.getRecordsOfSchedule(id));
        result.push(response.data)
      }
      return result;
    },
    postConsent: (data) => restClient.post(ENDPOINTS.CONSENTS, data).then((res) => res.data),
    getAllConsents: () => restClient.get(ENDPOINTS.CONSENTS).then((res) => res.data),
    getConsentWithText: (consentId) => () => restClient.get(ENDPOINTS.getConsentWithText(consentId)).then((res) => res.data),
    postConsentText: (consentId) => (data) => restClient.post(ENDPOINTS.getConsentWithText(consentId), data).then((res) => res.data),
    getConsentForType: (type) => () => restClient.get(ENDPOINTS.getConsentForType(type)).then((res) => res.data),
    getServicesForCategory: (categoryId) => () => restClient.get(ENDPOINTS.getServicesForCategory(categoryId)).then((res) => res.data),
    putCategoryText: (categoryId) => (data) => restClient.put(ENDPOINTS.updateCategory(categoryId), data).then((res) => res.data),
    postCategory: async (data) => {
      const formData = new FormData();
      formData.append('file', data.file, data.file.name);
      const fileResponse = await restClient.multipartPost(ENDPOINTS.UPLOAD_IMAGE, formData).then((res) => res.data);

      data.file = undefined;
      data.fileId = fileResponse.mediumFileId ? fileResponse.mediumFileId : fileResponse.smallFileId;
      return await restClient.post(ENDPOINTS.CATEGORIES, data).then((res) => res.data)
    },
    postService: async (data) => {
      const formData = new FormData();
      formData.append('file', data.file, data.file.name);
      const fileResponse = await restClient.multipartPost(ENDPOINTS.UPLOAD_IMAGE, formData).then((res) => res.data);

      data.file = undefined;
      data.fileId = fileResponse.mediumFileId ? fileResponse.mediumFileId : fileResponse.smallFileId;
      return await restClient.post(ENDPOINTS.SERVICES, data).then((res) => res.data)
    },
    putService: async (data) => {
      if (data.file) {
        const formData = new FormData();
        formData.append('file', data.file, data.file.name);
        const fileResponse = await restClient.multipartPost(ENDPOINTS.UPLOAD_IMAGE, formData).then((res) => res.data);
  
        data.file = undefined;
        data.fileId = fileResponse.mediumFileId ? fileResponse.mediumFileId : fileResponse.smallFileId;
      }
      return await restClient.put(ENDPOINTS.putService(data.id), data).then((res) => res.data)
    },
    deleteService: (serviceId) => () => restClient.delete(ENDPOINTS.deleteService(serviceId)).then((res) => res.data),
    addToCategory: (categoryId) => (data) => restClient.post(ENDPOINTS.addToCategory(categoryId), data).then((res) => res.data),
    getServiceCategories: (serviceId) => (data) => restClient.get(ENDPOINTS.serviceCategories(serviceId), data).then((res) => res.data),
    deleteFromCategory: (serviceId) => (data) => restClient.delete(ENDPOINTS.deleteFromCategory(serviceId, data.categoryId)).then((res) => res.data),
    getConsent: (consentId) => () => restClient.get(ENDPOINTS.getConsent(consentId)).then((res) => res.data),
    getUsers: () => restClient.get(ENDPOINTS.USERS).then((res) => res.data),
    postQuestionType: (data) => restClient.post(ENDPOINTS.QUESTIONS, data).then((res) => res.data),
    getQuestions: () => restClient.get(ENDPOINTS.QUESTIONS).then((res) => res.data),
    putQuestion: (questionId) => (data) => restClient.put(ENDPOINTS.updateQuestion(questionId), data).then((res) => res.data),
    getAnswersState: (userId) => () => restClient.get(ENDPOINTS.getAnswersState(userId)).then((res) => res.data),
    getQuestionnaire: (userId) => () => restClient.get(ENDPOINTS.getQuestionnaire(userId)).then((res) => res.data),
    postAnswers: (data) => restClient.post(ENDPOINTS.ANSWERS, data).then((res) => res.data),
    deleteFeatured: (serviceId) => () => restClient.post(ENDPOINTS.featured(serviceId), { featured: false }).then((res) => res.data),
    setFeatured: (serviceId) => () => restClient.post(ENDPOINTS.featured(serviceId), { featured: true }).then((res) => res.data),
    getFeaturedServices: () => restClient.get(ENDPOINTS.FEATURED).then((res) => res.data),
    addMaster: async (data) => {
      const formData = new FormData();
      formData.append('file', data.file, data.file.name);
      const fileResponse = await restClient.multipartPost(ENDPOINTS.UPLOAD_IMAGE, formData).then((res) => res.data);

      data.file = undefined;
      data.imageUrl = ENDPOINTS.getFileUrl(fileResponse.smallFileId);
      return await restClient.post(ENDPOINTS.MASTERS, data).then((res) => res.data);
    },
    getMasterServices: (masterId) => () => restClient.get(ENDPOINTS.getMasterServices(masterId)).then((res) => res.data),
    addServiceToMaster: (data) => restClient.post(ENDPOINTS.ADD_SERVICE_TO_MASTER, data).then((res) => res.data),
    getService: (serviceId) => () => restClient.get(ENDPOINTS.getService(serviceId)).then((res) => res.data),
    getRisks: (serviceId) => () => restClient.get(ENDPOINTS.getServiceRisks(serviceId)).then((res) => res.data),
    createFakeUser: (data) => restClient.post(ENDPOINTS.CREATE_FAKE_USER, data).then((res) => res.data),
    mergeUsers: (data) => restClient.post(ENDPOINTS.MERGE_FAKE_USER, data).then((res) => res.data),
    getUserRecords: (userId) => () => restClient.get(ENDPOINTS.getUserRecords(userId)).then((res) => res.data),
    removeUser: (userId) => () => restClient.delete(ENDPOINTS.user(userId)).then((res) => res.data),
    renameUser: (userId) => (data) => restClient.put(ENDPOINTS.user(userId), data).then((res) => res.data),
    editUser: async (data) => {
      if (data.file) {
        const formData = new FormData();
        formData.append('file', data.file, data.file.name);
        const fileResponse = await restClient.multipartPost(ENDPOINTS.UPLOAD_IMAGE, formData).then((res) => res.data);
  
        data.file = undefined;
        data.fileId = fileResponse.smallFileId;
      }
      return await restClient.put(ENDPOINTS.user(data.id), data).then((res) => res.data);
    },
    getUserSettings: (userId) => () => restClient.get(ENDPOINTS.userSettings(userId)).then((res) => res.data),
    updateUserSettings: (userId) => (data) => restClient.post(ENDPOINTS.userSettings(userId), data).then((res) => res.data),
    getMasterSettings: (masterId) => () => restClient.get(ENDPOINTS.masterSettings(masterId)).then((res) => res.data),
    updateMasterSettings: () => (data) => restClient.post(ENDPOINTS.updateMasterSettings(), data).then((res) => res.data),
    getGeneralAnalytics: (startTime, endTime) => () => restClient.get(ENDPOINTS.getGeneralMetrics(startTime, endTime)).then((res) => res.data),
    getUnconfirmedRecords: (masterId) => () => restClient.get(ENDPOINTS.getUnconfirmedRecords(masterId)).then((res) => res.data),
    confirmRecord: (recordId) => (data) => restClient.post(ENDPOINTS.postConfirmRecord(recordId), data).then((res) => res.data),
    confirmRecordByClient: (recordId) => (data) => restClient.post(ENDPOINTS.postAcceptRecord(recordId), data).then((res) => res.data),
    postConfirmationResult: (data) => restClient.post(ENDPOINTS.getConfirmationResult(), data).then((res) => res.data),
    getServiceDetailsByCode: (code) => () => restClient.get(ENDPOINTS.getServiceDetailsByCode(code)).then((res) => res.data),
    getUserHistory: (userId) => () => restClient.get(ENDPOINTS.getUserHistory(userId)).then((res) => res.data),
    getBookItems: () => () => restClient.get(ENDPOINTS.getBookItems()).then((res) => res.data),
    doRecord: () => (records) => restClient.post(ENDPOINTS.postRecords(), records).then((res) => res.data),
    getMasterNote: (profileId) => () => restClient.get(ENDPOINTS.getMasterNote(profileId)).then((res) => res.data),
    createMasterNote: () => (data) => restClient.post(ENDPOINTS.masterNote(), data).then((res) => res.data),
    updateMasterNote: () => (data) => restClient.put(ENDPOINTS.masterNote(), data).then((res) => res.data),
    getPrivacyPolicyLink: (type) => async () => {
      const consentsResponse = await restClient.get(ENDPOINTS.getConsentForType(type)).then((res) => res.data)
      if (consentsResponse.consents.length > 0) {
        return await restClient.get(ENDPOINTS.getConsentWithText(consentsResponse.consents[0].id)).then((res) => res.data)
      } else {
        throw new Error("PrivacyPolicy not found!")
      }
    },
    getPopularAnalytics: (startTime, endTime) => () => restClient.get(ENDPOINTS.getPopularMetrics(startTime, endTime)).then((res) => res.data),
    
    createRecordNote: () => (data) => restClient.post(ENDPOINTS.recordNote(), data).then((res) => res.data),
    updateRecordNote: () => (data) => restClient.put(ENDPOINTS.recordNote(), data).then((res) => res.data),
    createFeedback: () => (data) => restClient.post(ENDPOINTS.feedbacks(), data).then((res) => res.data),
    getFeedbacks: () => () => restClient.get(ENDPOINTS.feedbacks()).then((res) => res.data),
    getNewFeedbacks: () => () => restClient.get(ENDPOINTS.newFeedbacks()).then((res) => res.data),
    postFeedbackAnswered: (feedbackId) => (data) => restClient.post(ENDPOINTS.feedbackAnswered(feedbackId), data).then((res) => res.data),
    updateSettings: () => (data) => restClient.post(ENDPOINTS.updateSettings(), data).then((res) => res.data),
    getSettings: () => (data) => restClient.get(ENDPOINTS.updateSettings()).then((res) => res.data),
    getMastersAnalytics: (startTime, endTime) => () => restClient.get(ENDPOINTS.getMastersMetrics(startTime, endTime)).then((res) => res.data),
    generateAgreementCode: (recordId) => () => restClient.get(ENDPOINTS.generateAgreementCode(recordId)).then((res) => res.data),
    checkRecordCode: () => (data) => restClient.post(ENDPOINTS.checkRecordCode(), data).then((res) => res.data),
    acceptRecordCode: () => (data) => restClient.post(ENDPOINTS.acceptRecordCode(), data).then((res) => res.data),
    resetAgreementCode: () => (data) => restClient.post(ENDPOINTS.resetRecordCode(), data).then((res) => res.data),
    moveRecord: () => (data) => restClient.post(ENDPOINTS.moveRecord(), data).then((res) => res.data),
}
  
export default REQUESTS;